import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, filter, map, mergeMap, startWith } from 'rxjs';

export function getDataFromRoute(router: Router, route: ActivatedRoute): Observable<any> {
  return router.events.pipe(
    filter((e) => e instanceof NavigationEnd),
    startWith(() => true),
    map(() => route),
    mergeMap(() => {
      let current = route.root;
      while (current.firstChild) {
        current = current.firstChild;
      }
      return current.data;
    }),
  );
}
